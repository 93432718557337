import * as React from "react";
import Page from "~/components/Page";

const NotFoundPage = () => {
  return (
    <Page name="NotFound" seo={{ title: "Page not found" }}>
      <div className="d-flex align-center justify-center">
        <div>Page not found</div>
      </div>
    </Page>
  );
};

export default NotFoundPage;
